import React, { useEffect } from 'react';
import { graphql, PageProps } from 'gatsby';
import Section from '@components/Sections/Section';
import { NewTemplateQuery } from 'graphql-types';
import theme from '@theme/theme';
import {
  GroupTitle,
  TemplatePreviews,
} from '@components/Catalog/CatalogGroup/CatalogGroupStyled';
import TemplatePreview from '@components/Catalog/CatalogGroup/TemplatePreview/TemplatePreview';
import track from '@src/tracking/track';
import useSaveUtmParamsToLocalStorage from '@src/hooks/useSaveUtmParamsToLocalStorage';
import Cta from '@components/Shared/Cta/Cta';
import { INewTemplatePageContextProps } from '@contexts/interfaces';
import PageWrapper from './shared/PageWrapper';
import {
  FooterSubtitle,
  FooterTitle,
  SimilarTemplatesWrapper,
  TemplateFooter,
} from './NewTemplateStyled';

const NewTemplate = ({
  data,
  pageContext,
}: PageProps<NewTemplateQuery, INewTemplatePageContextProps>) => {
  const { identifier } = data?.newTemplate?.tracking ?? {};
  const templateLink = data?.newTemplate?.templateLink?.localized;

  const filteredTemplates =
    data?.allTemplates?.edges?.filter((edge) => {
      const isDifferentTemplate = edge.node._id !== data?.newTemplate?._id;
      const sameCategory =
        edge.node.category.filter(
          (c) => c._id === data?.newTemplate?.category[0]?._id
        ).length > 0;
      return sameCategory && isDifferentTemplate;
    }) ?? [];

  const currentCategoryName =
    data?.newTemplate?.category[0].categoryName?.localized;

  useSaveUtmParamsToLocalStorage();
  useEffect(() => {
    track({
      page: {
        categorySlug: data.newTemplate?.catalog?.slug?.localized,
        pageSlug: data.newTemplate?.slug?.localized,
      },
    });
  }, [
    data.newTemplate?.slug?.localized,
    data.newTemplate?.catalog?.slug?.localized,
  ]);

  return (
    <PageWrapper
      locale={pageContext.locale}
      identifier={identifier}
      navigationBar={data?.newTemplate?.catalog?.navigationBar}
      metadata={data?.newTemplate?.metadata}
      backgroundColor={theme.colors.gray.athensGrayLight}
      announcementId={
        data?.newTemplate?.catalog?.navigationBar?.announcement?._id
      }
      footerBar={data?.sanityFooterBar}
    >
      {data.newTemplate.content.map((content) => (
        <Section key={content._key} content={content} />
      ))}
      {filteredTemplates.length > 0 && (
        <SimilarTemplatesWrapper>
          <GroupTitle>
            {currentCategoryName
              ? `OTHER TEMPLATES IN ${currentCategoryName.toUpperCase()}`
              : ''}
          </GroupTitle>
          <TemplatePreviews>
            {filteredTemplates.map((template) => (
              <TemplatePreview
                key={template.node._id}
                template={template.node}
              />
            ))}
          </TemplatePreviews>
        </SimilarTemplatesWrapper>
      )}
      {!!templateLink && (
        <TemplateFooter>
          <FooterTitle>Get started with Collato</FooterTitle>
          <FooterSubtitle>
            Boost your productivity, collaborate, and manage your creative work,
            all in Collato. Get started now!
          </FooterSubtitle>
          <Cta
            externalLink={templateLink}
            buttonColor={{ hex: '#5F6CFF', __typename: 'SanityColor' }}
            title={{
              localized: 'Use template',
              __typename: 'SanityLocaleString',
            }}
            __typename="SanityCta"
          />
        </TemplateFooter>
      )}
    </PageWrapper>
  );
};

export const query = graphql`
  query NewTemplate(
    $id: String!
    $locale: String!
    $catalogId: String!
    $footerBarId: String!
  ) {
    sanityFooterBar(_id: { eq: $footerBarId }) {
      ...FooterBar
    }
    newTemplate: sanityNewTemplate(_id: { eq: $id }) {
      _id
      _key
      slug {
        localized(locale: $locale)
      }
      category {
        _id
        categoryName {
          localized(locale: $locale)
        }
      }
      catalog {
        slug {
          localized(locale: $locale)
        }
        navigationBar {
          ...Header
        }
      }
      title {
        localized(locale: $locale)
      }
      templateLink {
        localized(locale: $locale)
      }
      description {
        localized(locale: $locale)
      }
      content {
        ...ContentSections
      }
      metadata {
        ...SEO
      }
      tracking {
        ...tracking
      }
      carouselImages {
        ...GatsbyImage
      }
      redirects
    }
    catalogCategory: allSanityCatalogCategory(
      filter: { catalog: { _id: { eq: $catalogId } } }
    ) {
      edges {
        node {
          _id
          _key
          categoryName {
            localized(locale: $locale)
          }
          iconBackgroundColor {
            hex
          }
          icon {
            asset {
              gatsbyImageData(fit: FILLMAX, placeholder: NONE)
            }
          }
        }
      }
    }
    allTemplates: allSanityNewTemplate(
      filter: { catalog: { _id: { eq: $catalogId } } }
    ) {
      edges {
        node {
          _id
          _key
          slug {
            localized(locale: $locale)
          }
          coverImageBgColor {
            hex
          }
          coverImage {
            ...GatsbyImageWithUrl
          }
          category {
            _id
            _key
            categoryName {
              localized(locale: $locale)
            }
            iconBackgroundColor {
              hex
            }
            icon {
              asset {
                gatsbyImageData(fit: FILLMAX, placeholder: NONE)
                url
              }
            }
          }
          catalog {
            slug {
              localized(locale: $locale)
            }
            navigationBar {
              ...Header
            }
          }
          title {
            localized(locale: $locale)
          }
          description {
            localized(locale: $locale)
          }
        }
      }
    }
  }
`;

// ts-prune-ignore-next
export default NewTemplate;
